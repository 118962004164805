import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  {
    path: "portfolios",
    name: "portfolios",
    component: () => import("@/modules/hollymontt-admin/views/PortfoliosCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "portfolios",
      title: "Carteras de usuarios",
    },
  },
  {
    path: "portfolios/:id",
    name: "portfoliosEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["portfolios"],
      serviceName: "portfolios",
      title: "Cartera de usuario",
    },
  },
];
