import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  { path: "billing", name: "billing", redirect: "invoices" },

  {
    path: "billing/invoices",
    name: "invoices",
    component: () => import("@/modules/hollymontt-admin/views/InvoicesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "invoices",
      title: "Facturas de usuarios",
    },
  },
  {
    path: "billing/invoices/:id",
    name: "invoicesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["invoices"],
      serviceName: "invoices",
      title: "Factura de cliente",
    },
  },

  {
    path: "billing/coupons",
    name: "coupons",
    component: () => import("@/modules/hollymontt-admin/views/CouponsCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "coupons",
      title: "Copones de descuento",
    },
  },
  {
    path: "billing/coupons/:id",
    name: "couponsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["coupons"],
      serviceName: "coupons",
      title: "Cupón de descuento",
    },
  },
];
