import permissions from "@/router/userPermissions";

import IntranetTabs from "@/layouts/intranet-tabs/Index.vue";

import billingRoutes from "@/modules/hollymontt-admin/router/billingRoutes";
import clientsRoutes from "@/modules/hollymontt-admin/router/clientsRoutes";
import educationRoutes from "@/modules/hollymontt-admin/router/educationRoutes";
import portfoliosRoutes from "@/modules/hollymontt-admin/router/portfoliosRoutes";
import settingsRoutes from "@/modules/hollymontt-admin/router/settingsRoutes";
import subscriptionsRoutes from "@/modules/hollymontt-admin/router/subscriptionsRoutes";
import usersRoutes from "@/modules/hollymontt-admin/router/usersRoutes";
import coursesRoutes from "@/modules/hollymontt-admin/router/coursesRoutes";
import coursePhasesRoutes from "@/modules/hollymontt-admin/router/coursePhasesRoutes";

export default [
  {
    path: "/",
    component: IntranetTabs,
    children: [
      {
        path: "",
        component: () => import("@/views/Dashboard.vue"),
        name: "dashboard",
        meta: {
          auth: permissions.scopes.admin,
          title: "Panel de control",
          hideBreadcrumb: true,
          serviceName: "dashboard",
        },
      },
      ...billingRoutes,
      ...clientsRoutes,
      ...coursesRoutes,
      ...coursePhasesRoutes,
      ...educationRoutes,
      ...portfoliosRoutes,
      ...settingsRoutes,
      ...subscriptionsRoutes,
      ...usersRoutes,
    ],
  },
];
