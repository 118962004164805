import Vue from "vue";

import VJsf from "@koumoul/vjsf/lib/VJsf.js";
import "@koumoul/vjsf/lib/VJsf.css";
// load third-party dependencies (markdown-it, vuedraggable)
// you can also load them separately based on your needs
// import '@koumoul/vjsf/lib/deps/third-party.js'

Vue.component("VJsf", VJsf);

export default new Vue({});
