import apiClient from "@/services/ApiClient";

export function configActions() {
  const saveColumns = (name, data) => {
    const payload = { name: name, data: data };
    return apiClient.post("/crud-configurations/save-columns", payload);
  };

  return { saveColumns };
}
