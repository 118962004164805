import store from "@/store/index";
import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

import permissions from "@/router/userPermissions";
import adminRoutes from "@/modules/hollymontt-admin/router";

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: "/login",
    component: () => import("@/layouts/intranet-empty/Index.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/views/UserLogin.vue"),
        meta: {
          auth: permissions.roles.guest,
          title: "Acceso de usuario",
        },
      },
    ],
  },
];

const notFoundRoute = [
  {
    path: "*",
    component: () => import("@/views/errors/404.vue"),
  },
];

const routes = [...baseRoutes, ...adminRoutes, ...notFoundRoute];

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/" : "/admin",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === undefined) {
    // 404 paths
    next();
  } else {
    let allowedRoles = to.matched.map(record => record.meta.auth).filter(record => record);
    if (allowedRoles.length > 1) {
      // Only one auth can be used per path
      return null;
    }

    const loggedIn = Cookies.get("auth_token") && store.state.user.loggedIn;

    if (loggedIn) {
      if (allowedRoles[0] === "guest") {
        next(from.path);
      } else {
        const userRoles = store.state.user.roles.map(role => role.id);
        const authorized = allowedRoles
          .flat()
          .filter(allowedRole => userRoles.includes(allowedRole));
        if (authorized.length) {
          next();
        } else {
          from.name === "login"
            ? next({ name: "dashboard", query: { forbidden: 1 } })
            : next({ path: from.fullPath, query: { forbidden: 1 } });
        }
      }
    } else {
      if (store.state.user.loggedIn) store.commit("CLEAR_USER_DATA");

      if (!allowedRoles.length || allowedRoles[0] === "guest") {
        next();
      } else {
        store.state.loginRedirect = to.fullPath;
        next({ name: "login" });
      }
    }
  }
});

export default router;
