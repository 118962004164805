import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";
import coursesRoutesTabs from "@/modules/hollymontt-admin/router/coursesRoutesTabs";

export default [
  {
    path: "courses",
    name: "courses",
    component: () => import("@/modules/hollymontt-admin/views/SubscriptionsCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "courses",
      title: "Convocatorias",
    },
  },
  {
    path: "courses/:id/edit",
    name: "coursesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "courses", breadcrumbDisabled: true }],
      tabs: coursesRoutesTabs,
      serviceName: "courses",
      title: "Convocatoria",
    },
  },
  {
    path: "courses/:id/course-clients",
    name: "courses-courseClients",
    component: () => {
      return import("@/modules/hollymontt-admin/views/companies-crud/CompaniesCoursesClients.vue");
    },
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "courses", breadcrumbDisabled: true }],
      tabs: coursesRoutesTabs,
      serviceName: "course-clients",
      title: "Alumnos del curso",
    },
  },

  {
    path: "courses/:id/course-phases",
    name: "courses-coursePhases",
    component: () => {
      return import("@/modules/hollymontt-admin/views/companies-crud/CompaniesCoursesPhases.vue");
    },
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "courses", breadcrumbDisabled: true }],
      tabs: coursesRoutesTabs,
      serviceName: "course-phases",
      title: "Fases del curso",
    },
  },

  {
    path: "course-clients",
    name: "courseClients",
    component: () => import("@/modules/hollymontt-admin/views/SubscriptionsCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "courses",
      title: "Lista de alumnos",
    },
  },
  {
    path: "course-clients/:id/edit",
    name: "courseClientsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "courseClients", breadcrumbDisabled: true }],
      serviceName: "course-clients",
      title: "Edición de alumno",
    },
  },
  /*{
    path: "courses/:id/course-clients/edit",
    name: "courseClientsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "courses-courseClients", breadcrumbDisabled: true }],
      serviceName: "course-clients",
      title: "Edición de alumno",
    },
  },*/
];
