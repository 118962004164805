import { crudMdActions } from "@/services/crud-md-actions";
import { getListActions } from "@/services/crud-md-actions/getListActions";
import { createActions } from "@/services/crud-md-actions/createActions";

const name = "dictionary-terms";

export default {
  name,
  ...crudMdActions(name),
  tab: { getListActions, createActions },
};
