import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  { path: "settings", name: "settings", redirect: "emailTemplates" },
  {
    path: "settings/email-templates",
    name: "emailTemplates",
    component: () => import("@/modules/hollymontt-admin/views/EmailTemplatesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "mailing-templates",
      title: "Plantillas de correo",
    },
  },
  {
    path: "settings/email-templates/:id",
    name: "emailTemplatesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "emailTemplates"],
      serviceName: "mailing-templates",
      title: "Plantilla de correo",
    },
  },
];
