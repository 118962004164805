import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  {
    path: "course-phases",
    name: "coursePhases",
    component: () => import("@/modules/hollymontt-admin/views/SubscriptionsCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "course-phases",
      title: "Fases",
    },
  },
  {
    path: "course-phases/:id",
    name: "coursePhasesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "coursePhases", breadcrumbDisabled: true }],
      serviceName: "course-phases",
      title: "Fase de convocatoria",
    },
  },
];
