import permissions from "@/router/userPermissions";

import crudUpdateMd from "@/components/crud-md/actions/Edit";
import clientsRoutesTabs from "@/modules/hollymontt-admin/router/clientsRoutesTabs";
import companiesRoutesTabs from "@/modules/hollymontt-admin/router/companiesRoutesTabs";

export default [
  {
    path: "clients/list",
    component: () => {
      return import("@/modules/hollymontt-admin/views/clients-crud");
    },
    name: "clients",
    meta: { auth: permissions.scopes.admin, title: "Clientes", serviceName: "clients" },
  },
  {
    path: "clients/:id/edit",
    component: crudUpdateMd,
    name: "clientsEdit",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clients"],
      tabs: clientsRoutesTabs,
      title: "Datos del cliente",
      serviceName: "clients",
    },
  },
  {
    path: "clients/:id/portfolios",
    component: () => {
      return import("@/modules/hollymontt-admin/views/clients-crud/ClientsCrudPortfolios.vue");
    },
    name: "clients-portfolios",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clients"],
      tabs: clientsRoutesTabs,
      title: "Carteras de cliente",
      serviceName: "portfolios",
    },
  },
  {
    path: "clients/:id/subscription",
    component: () => {
      return import("@/modules/hollymontt-admin/views/clients-crud/ClientsCrudSubscriptions");
    },
    name: "clients-subscription",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clients"],
      tabs: clientsRoutesTabs,
      title: "Suscripciones de cliente",
      serviceName: "subscription-events",
    },
  },

  {
    path: "clients/companies/",
    component: () => {
      return import("@/modules/hollymontt-admin/views/companies-crud");
    },
    name: "companies",
    meta: { auth: permissions.scopes.admin, title: "Empresas", serviceName: "companies" },
  },
  {
    path: "clients/companies/:id/edit",
    component: crudUpdateMd,
    name: "companiesEdit",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["companies"],
      tabs: companiesRoutesTabs,
      title: "Datos de la empresa",
      serviceName: "companies",
    },
  },
  {
    path: "clients/companies/:id/courses",
    component: () => {
      return import("@/modules/hollymontt-admin/views/companies-crud/CompaniesCourses.vue");
    },
    name: "companies-courses",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["companies"],
      tabs: companiesRoutesTabs,
      title: "Datos de la empresa",
      serviceName: "courses",
    },
  },
];
