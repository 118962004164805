import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";
import educationTrainingsRoutesTabs from "@/modules/hollymontt-admin/router/educationTrainingsRoutesTabs";

export default [
  { path: "education", name: "education", redirect: "dictionary" },

  {
    path: "education/trainings",
    component: () => import("../views/trainings-crud"),
    name: "trainingsCrud",
    meta: { auth: permissions.scopes.admin, title: "Cursos", serviceName: "trainings" },
  },
  {
    path: "education/trainings/:id/edit",
    component: crudUpdateMd,
    name: "trainingsCrudEdit",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainings"],
      tabs: educationTrainingsRoutesTabs,
      title: "Datos del curso",
      serviceName: "trainings",
    },
  },
  {
    path: "education/trainings/:id/lessons",
    component: () => {
      return import("@/modules/hollymontt-admin/views/trainings-crud/TrainingsCrudLessons.vue");
    },
    name: "trainingsCrud-lessons",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainings"],
      tabs: educationTrainingsRoutesTabs,
      title: "Lecciones del curso",
      serviceName: "lessons",
    },
  },

  {
    path: "education/trainings/lessons",
    name: "lessons",
    component: () => import("@/modules/hollymontt-admin/views/PortfoliosCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "lessons",
      title: "Lecciones de curso",
    },
  },
  {
    path: "education/trainings/lessons/:id",
    name: "lessonsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["lessons"],
      serviceName: "lessons",
      title: "Lección",
    },
  },

  {
    path: "education/dictionary-of-terms",
    name: "dictionaryCrud",
    component: () => import("@/modules/hollymontt-admin/views/DictionaryCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "education", breadcrumbDisabled: true }],
      serviceName: "dictionary-terms",
      title: "Diccionario de términos",
    },
  },
  {
    path: "education/dictionary-of-terms/:id",
    name: "dictionaryCrudEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "education", breadcrumbDisabled: true }, "dictionaryCrud"],
      serviceName: "dictionary-terms",
      title: "Término",
    },
  },

  {
    path: "education/case-studies",
    name: "caseStudiesCrud",
    component: () => import("@/modules/hollymontt-admin/views/CaseStudiesCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "education", breadcrumbDisabled: true }],
      serviceName: "caseStudies",
      title: "Case studies",
    },
  },
  {
    path: "education/case-studies/:id",
    name: "caseStudiesCrudEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "education", breadcrumbDisabled: true }, "caseStudiesCrud"],
      serviceName: "caseStudies",
      title: "Case study",
    },
  },

  {
    path: "education/videotutorials",
    name: "videoTutorialsCrud",
    component: () => import("@/modules/hollymontt-admin/views/VideotutorialsCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "education", breadcrumbDisabled: true }],
      serviceName: "videotutorials",
      title: "Diccionario de términos",
    },
  },
  {
    path: "education/videotutorials/:id",
    name: "videoTutorialsCrudEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "education", breadcrumbDisabled: true }, "videoTutorialsCrud"],
      serviceName: "videotutorials",
      title: "Videotutorial",
    },
  },
];
