// src/plugins/VueSanitize.js
import Vue from "vue";
import VueSanitize from "vue-sanitize";

const defaultOptions = {
  allowedTags: ["a", "b"],
  allowedAttributes: {
    a: ["href"],
  },
};

Vue.use(VueSanitize, defaultOptions);

export default new Vue({});
