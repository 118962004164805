<template>
  <view-base>
    <div class="fill-height d-flex flex-column align-stretch prd-maxwidth-100">
      <div class="flex-shrink-1 flex-grow-0 prd-maxwidth-100">
        <the-tools-bar>
          <template #tools-right>
            <tool-bar-btns
              :formId="formId"
              :submit-loading="submitLoading"
              @closeItem="closeItem"
              @handleCloseAfterSubmit="handleCloseAfterSubmit($event)"
            />
          </template>
        </the-tools-bar>
      </div>

      <v-col class="flex-shrink-1 flex-grow-0">
        <slot name="header"></slot>
      </v-col>

      <v-col v-if="isLoading" class="d-flex justify-center fill-height prd-maxwidth-100 pt-8">
        <v-progress-circular class="my-12" :size="50" :color="loadingColor" indeterminate />
      </v-col>

      <v-col v-else>
        <form-vjsf :form-data="formData" :form-id="formId" @submitForm="submitForm" />
      </v-col>
    </div>
  </view-base>
</template>

<script>
import crudMdEditMixin from "@/mixins/crudMdEditMixin";

export default {
  mixins: [crudMdEditMixin],
  components: {
    FormVjsf: () => import("@/components/form-vjsf"),
    ViewBase: () => import("@/layouts/intranet-tabs/ViewBase"),
    TheToolsBar: () => import("@/components/TheToolsBar.vue"),
    ToolBarBtns: () => import("@/components/crud-md/actions/EditToolBarBtns"),
  },
  data: () => ({
    formData: {
      id: null,
      model: {},
      schema: {},
      options: {},
    },
  }),
  async beforeMount() {
    this.setLoadingColor();
    this.currentService = this.$store.state.currentService;
    this.itemId = this.$route.params["id"];
    this.itemTitle = "#" + this.itemId;
    await this.updateViewTitle();
  },
  async mounted() {
    await this.getItemData();
  },
  methods: {
    async submitForm() {
      try {
        this.submitLoading = true;
        const response = await this.currentService.updateItem(
          this.formData.id,
          this.formData.model
        );

        const { errorMessage } = response.data;

        if (errorMessage) {
          this.$notifyError("Datos incorrectos", errorMessage);
        } else {
          this.$notifySuccess();
          if (this.closeAfterSubmit) this.closeItem();
        }
      } catch {
        this.$notifyError();
      } finally {
        this.submitLoading = false;
      }
    },
    async getItemData() {
      try {
        this.isLoading = true;
        const response = await this.currentService.getUpdateItemForm(this.itemId);

        if (response.data.title) this.itemTitle += " | " + response.data.title;
        await this.updateViewTitle();

        this.formData = response.data.vjsfData;
        this.isLoading = false;
      } catch {
        this.$notifyError();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
