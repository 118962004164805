import caseStudiesService from "@/modules/hollymontt-admin/services/caseStudiesService";
import clientsService from "@/modules/hollymontt-admin/services/clientsService";
import companiesService from "@/modules/hollymontt-admin/services/companiesService";
import couponsService from "@/modules/hollymontt-admin/services/couponsService";
import coursesService from "@/modules/hollymontt-admin/services/coursesService";
import courseClientsService from "@/modules/hollymontt-admin/services/courseClientsService";
import coursePhasesService from "@/modules/hollymontt-admin/services/coursePhasesService";
import dashboardServices from "@/modules/hollymontt-admin/services/dashboardService";
import dictionaryService from "@/modules/hollymontt-admin/services/dictionaryService";
import emailTemplatesService from "@/modules/hollymontt-admin/services/emailTemplatesService";
import invoicesService from "@/modules/hollymontt-admin/services/invoicesService";
import subscriptionsService from "@/modules/hollymontt-admin/services/subscriptionsService";
import subscriptionsEventsService from "@/modules/hollymontt-admin/services/subscriptionsEventsService";
import portfoliosService from "@/modules/hollymontt-admin/services/portfoliosService";
import userService from "@/modules/hollymontt-admin/services/userService";
import videotutorialsService from "@/modules/hollymontt-admin/services/videotutorialsService";
import lessonsService from "@/modules/hollymontt-admin/services/lessonsService";
import trainingsService from "@/modules/hollymontt-admin/services/trainingsService";

export default [
  caseStudiesService,
  clientsService,
  companiesService,
  couponsService,
  coursesService,
  courseClientsService,
  coursePhasesService,
  dashboardServices,
  dictionaryService,
  emailTemplatesService,
  invoicesService,
  lessonsService,
  subscriptionsService,
  subscriptionsEventsService,
  userService,
  portfoliosService,
  trainingsService,
  videotutorialsService,
];
