const roles = {
  guest: "guest",
  superAdmin: "f1930218-35bd-4871-990d-066b7ee16725",
  admin: "8a590115-665d-4577-ae43-fc95bbd4b566",
  client: "ac1ac598-7229-11ed-a1eb-0242ac120002",
};

const scopes = {
  admin: [roles.superAdmin, roles.admin],
  client: [roles.client],
  all: Object.values(roles).filter(r => r !== "guest"),
};

const permissions = { roles, scopes };

export default permissions;
