import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  {
    path: "users",
    name: "users",
    component: () => import("@/modules/hollymontt-admin/views/UsersCrud"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "users",
      title: "Usuarios",
    },
  },
  {
    path: "users/:id",
    name: "usersEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["users"],
      serviceName: "users",
      title: "Usuario",
    },
  },
];
