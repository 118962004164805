import { crudMdActions } from "@/services/crud-md-actions";
import { getListActions } from "@/services/crud-md-actions/getListActions";
import { createActions } from "@/services/crud-md-actions/createActions";

const name = "caseStudies";

export default {
  name,
  ...crudMdActions(name, "case-studies"),
  tab: { getListActions, createActions },
};
