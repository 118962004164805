import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.min.css";
import minifyTheme from "minify-css-string";
//import LRU from "lru-cache"

Vue.use(Vuetify);

let colors;
let darkColors;
/*
const themeCache = new LRU({
  max: 10,
  maxAge: 1000 * 60 * 60, // 1 hour
})
*/

if (window.location.hostname.split(".")[0] === "eecs") {
  /* Decoralife colors */
  colors = {
    primary: "#669999",
    secondary: "#1f3d30",
    anchor: "#669999",
    error: "#ff4344",
    info: "#669999",
    success: "#78c644",
    warning: "#ff9351",
  };
  darkColors = {
    primary: "#669999",
    secondary: "#1f3d30",
    anchor: "#669999",
    error: "#ff4344",
    info: "#669999",
    success: "#78c644",
    warning: "#ff9351",
  };
} else {
  colors = {
    primary: "#00bba7",
    secondary: "#00CBBC",
    anchor: "#00a186",
    error: "#FF4344",
    info: "#195AE0",
    success: "#00CBBC",
    warning: "#FFBF3F",
  };
  darkColors = colors;
}

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: colors,
      dark: darkColors,
      options: {
        minifyTheme,
        //themeCache,
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});

export default vuetify;
