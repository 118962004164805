import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  {
    path: "subscriptions",
    name: "subscriptions",
    component: () => import("@/modules/hollymontt-admin/views/SubscriptionsCrud.vue"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "subscriptions",
      title: "Suscripciones",
    },
  },
  {
    path: "subscriptions/:id",
    name: "subscriptionsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["subscriptions"],
      serviceName: "subscriptions",
      title: "Suscripción de usuario",
    },
  },
];
